body {
    width: 100%;
    max-width: 100%;
    background-image: url('../public/macfullscreenapbg.png');
}

hr {
    border-top: solid 1px #aaaaaa !important;
    background: transparent;
    width: 100%;
}

ul {
    list-style-type: square;
}

.bold {
    font-weight: bold;
}

.App {
    margin: auto;
    background-color: white;
    border-radius: 20px;
    padding: 12px;
    width: 75%;
    max-width: 75%;
}

.contact-info {
    text-align: center;
    height: 85%;
    background: white;
    border-bottom: none;
}

.contact-name {
    font-size: calc(27px + 2vmin);
}

.section-header {
    font-family: 'Courier New', ui-rounded;
    font-size: 1.17em;
}

.summary-text {
    text-indent: 1em;
}

.education {
    display: flex;
    flex-direction: column;
}

.education-header {
    display: flex;
    justify-content: space-between;
}

.job-entry {
    display: flex;
    flex-direction: column;
}

.job-header {
    display: flex;
    justify-content: space-between;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    /* Adjust the styles for smaller screens */
    .App {
        width: 85%;
        max-width: 85%;
    }

    .contact-name {
        font-size: calc(40px + 2vmin);
    }

    .contact-location {
        font-size: 1.5rem;
    }
}
